import request from '@/utils/request'

// 获取路由
export const getRouters = () => {
  return request({
    url: '/getRouters',
    method: 'get'
  })
}

//记录路由操作
export const accessToRecords = (data) => {
  return request({
    url: '/accessToRecords',
    method: 'post',
    data
  })
}
