<template>
    <div :class="{'has-logo':showLogo}">
        <logo v-if="showLogo" :collapse="isCollapse" />
        <el-scrollbar wrap-class="scrollbar-wrapper">
            <el-menu
                :default-active="activeMenu"
                :collapse="isCollapse"
                :unique-opened="true"
                :active-text-color="settings.theme"
                :collapse-transition="false"
                mode="vertical"
            >
              <sidebar-item
                    v-for="(route, index) in routelist[0].children"
                    :key="route.path  + index"
                    :item="route"
                    :base-path="routelist[0].path+'/'+route.path"
                />
            </el-menu>
        </el-scrollbar>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";

export default {
    components: { SidebarItem, Logo },
    mounted(){
      fuckConsole.log('wwaaaa',this.routelist)
    },
    computed: {
        ...mapState(["settings"]),
        ...mapGetters(["permission_routes", "sidebar"]),
        activeMenu() {
            const route = this.$route;
            const { meta, path } = route;
            // if set path, the sidebar will highlight the path you set
            if (meta.activeMenu) {
                return meta.activeMenu;
            }
            return path;
        },
        showLogo() {
            return this.$store.state.settings.sidebarLogo;
        },
        isCollapse() {
            return !this.sidebar.opened;
        },
        routelist(){

            return this.permission_routes.filter((item)=>{
              if(item.path!=''){
                //  !item.hidden&&
         return this.$route.path.indexOf(item.path)!=-1}
       })
        }
    },

};
</script>
