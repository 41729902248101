import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/auth-redirect', '/bind', '/register']

router.beforeEach((to, from, next) => {
  NProgress.start()
   // set page title
   document.title = getPageTitle(to.meta.title)
  if (getToken()) {
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      if (store.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(res => {
          // 拉取user_info
          const roles = res.roles
          store.dispatch('GenerateRoutes', { roles }).then(accessRoutes => {
          // 测试 默认静态页面
          // store.dispatch('permission/generateRoutes', { roles }).then(accessRoutes => {
            // 根据roles权限生成可访问的路由表
            accessRoutes.forEach(i => {
              router.addRoute(i) // 动态添加可访问路由表
            })
            next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
          })
        })
          .catch(err => {
            store.dispatch('FedLogOut').then(() => {
              Message.error(err)
              next({ path: '/' })
            })
          })
      } else {
        // 路由首页跳转
        const routes=store.getters.permission_routes
        fuckConsole.log('test',routes)
        const nohidden=routes.filter((obj)=>obj.hidden!=true)
        if(to.path === '/index'||to.path === '/'){
          // 进入的首屏页面
          if(nohidden[0].children[0].children!=undefined&&nohidden[0].children[0].children.length>=1){
            fuckConsole.log(222,nohidden[0].path+"/"+nohidden[0].children[0].path+"/"+nohidden[0].children[0].children[0].path)
            next({path:nohidden[0].path+"/"+nohidden[0].children[0].path+"/"+nohidden[0].children[0].children[0].path})
          }else{
            fuckConsole.log(333,nohidden[0].path+"/"+nohidden[0].children[0].path)
            next({path:nohidden[0].path+"/"+nohidden[0].children[0].path})
          }

        }else{
          next()
        }

        // 没有动态改变权限的需求可直接next() 删除下方权限判断 ↓
        // if (hasPermission(store.getters.roles, to.meta.roles)) {
        //   next()
        // } else {
        //   next({ path: '/401', replace: true, query: { noGoBack: true }})
        // }
        // 可删 ↑
      }
    }
    if (to.matched && to.matched.length > 2) {
      for (let i = 0; i < to.matched.length; i++) {
        const element = to.matched[i]
        if (element.components.default.name === 'Golda'||element.components.default.name === 'Usera') {
          to.matched.splice(i, 1)
        }
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
