<template>
  <div :class="classObj" class="app-wrapper">
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <sidebar class="sidebar-container" />
    <!-- topbar 头部-->
    <div :class="{hasTagsView:needTagsView}" class="top-container">
      <el-scrollbar wrap-class="scrollbar-wrapper" >
            <!-- <el-menu
              :default-active="activeMenu"
                :background-color="variables.menuBg"
                :text-color="variables.menuText"
                :unique-opened="true"
                :active-text-color="theme"
                :collapse-transition="false"
                 mode="horizontal"
            >
              <el-menu-item v-for="(route, index) in routelist" :index="resolvePath(route.path)"  :key="route.path  + index" >
              <app-link :to="resolvePaths(route)" >
                      <div v-if="route.meta">{{route.meta.title}}
                      </div>
                  <div v-else>{{route.children[0].meta.title}}
                  </div>
             </app-link>
              </el-menu-item>

            </el-menu> -->
          <ul class="active">
            <li  v-for="(route, index) in routelist" :index="resolvePath(route.path)"  :key="route.path  + index" :class="activeMenu==resolvePath(route.path)?'is-active':''" >
                  <app-link :to="resolvePaths(route)" >
                      <div v-if="route.meta">{{route.meta.title}}
                      </div>
                  <div v-else>{{route.children[0].meta.title}}
                  </div>
             </app-link>
            </li>
          </ul>

        </el-scrollbar>

        <!-- <div v-for="(route, index) in routelist" :index="resolvePath(route.path)"  :key="route.path  + index" >
            <router-link  :to="resolvePaths(route)">11</router-link>
        </div> -->

    </div>


    <div :class="{hasTagsView:needTagsView}" class="main-container">
      <div :class="{'fixed-header':fixedHeader}">
        <navbar />
        <tags-view v-if="needTagsView" />
      </div>
      <app-main />
      <right-panel v-if="showSettings">
        <settings />
      </right-panel>
    </div>
  </div>
</template>

<script>
import RightPanel from '@/components/RightPanel'
import { AppMain, Navbar, Settings, Sidebar, TagsView } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { mapState,mapGetters } from 'vuex'
import variables from "@/assets/styles/variables.scss";
import { isExternal } from '@/utils/validate'
import path from 'path'
import AppLink from './components/Sidebar/Link'
export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    RightPanel,
    Settings,
    Sidebar,
    TagsView,
    AppLink
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
      showSettings: state => state.settings.showSettings,
      needTagsView: state => state.settings.tagsView,
      fixedHeader: state => state.settings.fixedHeader,
      theme:state => state.settings.theme
    }),
    ...mapGetters(["permission_routes"]),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    },
     activeMenu() {
            const route = this.$route;
            const { meta, path } = route;
            // if set path, the sidebar will highlight the path you set
            if (meta.activeMenu) {
                return meta.activeMenu;
            }
            // 匹配两个/之间的字符串
            // return path;
            fuckConsole.log(123,path.match( /\/.*?(?=\/)/)[0])
            return path.match( /\/.*?(?=\/)/)[0]
        },
     variables() {
         return variables;
      },
     routelist(){
      //  fuckConsole.log(JSON.stringify( this.permission_routes.filter((item)=>{
      //    return !item.hidden
      //  })))
       return this.permission_routes.filter((item)=>{
         return !item.hidden
       })
     },
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },
      resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }

      return path.resolve(routePath, routePath)
    },
    // 该方法判断头部路由跳转，多级跳转到第一个
    resolvePaths(route) {

      if (isExternal(route.path)) {
        return route.path
      }

      if(route.children){
        if(route.children[0].children){
           return route.path+"/"+route.children[0].path+"/"+route.children[0].children[0].path
        }else{
         return route.path+"/"+route.children[0].path}
      }else{
        return  route.path+"/"
      }


    },


  }
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/styles/mixin.scss";
  @import "~@/assets/styles/variables.scss";

  .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;

    &.mobile.openSidebar {
      position: fixed;
      top: 0;
    }
  }

  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px)
  }

  .mobile .fixed-header {
    width: 100%;
  }
  .active{
    background-color: rgb(48, 65, 86);
    border-bottom: solid 1px #e6e6e6;
        border-right: none;
        box-sizing: inherit;
        list-style: none;
        position: relative;
        margin:0 ;
        padding-left: 0;
      li{
    color: rgb(191, 203, 217);
    border-bottom-color: transparent;
    background-color: rgb(48, 65, 86);
    float: left;
    height: 60px;
    line-height: 60px;
    margin: 0;
    border-bottom: 2px solid transparent;
    font-size: 14px;
        padding: 0 20px;
    list-style: none;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    white-space: nowrap;
        }
         .is-active{
    border-bottom: 2px solid #1890ff;
    color: rgb(24, 144, 255);
  }
  }
  .active::before{
    display: table;
    content: "";
  }
  .active::after{
     display: table;
    content: "";
    clear: both;
  }

</style>
